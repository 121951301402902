/* .header {
  position: fixed;
  top:0;
  width: 100%;
  height: 70px;
  background: transparent;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
  box-shadow: 0 0 2px 0 #969696;
} */

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 70px; */
    height: 40px;

    /* background: transparent; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 10;
    box-shadow: 0 0 2px 0 #969696;
    background-color: #cb9286;
    color: #333;
    transform: translateY(0);
    transition: transform 0.4s ease;
}
.header.shadow {
    box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
    /* background: #cb9286; */
}

.header.hidden {
    transform: translateY(-110%);
}

.menu_list_button {
    display: none;
}
.menu_list_button_image {
    width: 35px;
    height: 35px;
}
.logo_name {
    /* font-family: 'Rochester', cursive;
  font-size: 30px;  remove if we want text istead of logo*/
    /* align-self: flex-end; */
    /* padding: 0 10px 0 10px; */
    /* position: relative; */
    width: 60px;
    height: 40px;
    position: relative;
}
.logo_dd {
    width: 60px;
    height: 60px;
    left: 0px;
    background-color: #cb9286;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: absolute;
    z-index: 10;

    /* align-self: flex-end; */
}

.left_menu {
    align-self: center;
    font-size: 15px;
    display: flex;
}

.right_menu {
    align-self: center;
    font-size: 15px;
    display: flex;
}

.menu_item {
    /* margin: 0 10px 0 10px; */
    width: 103px;
    align-self: center;

    cursor: pointer;
    /* color: #ead3ce; */
    /* color: #c7b299; */
    color: #ffe5c6;

    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 1em;
}
.menu_open {
    height: 400px;
    width: 100%;
    top: 40px;
    position: absolute;
    background-color: #cb9286;
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: 1s appear forwards;
}
@keyframes appear {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.menu_item_mobile {
    /* margin: 0 10px 0 10px; */
    width: 100%;
    cursor: pointer;
    /* color: #c7b299; */
    color: #ffe5c6;
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 1em;
    margin: 20px 0 20px 0;
    animation: 0.5s slideIn forwards;
    transition: color 0.2s ease-in-out;
}
@keyframes slideIn {
    0% {
        transform: translateX(-5%);
    }
    100% {
        transform: translateX(0);
    }
}
@media only screen and (max-width: 679px) {
    .logo_name {
        order: 1;
    }
    .menu_item {
        display: none;
    }
    .header {
        /* flex-direction: column; */
        justify-content: space-between;
    }
    .menu_list_button {
        display: flex;
        align-self: center;
        margin-right: 20px;
        order: 1;
        color: #c7b299;
        z-index: 10;
    }
}
/* @media only screen and (max-width: 520px) {
  .menu_item {
    cursor: pointer;
  }

}
@media only screen and (max-width: 440px) {
  .menu_item {
    cursor: pointer;
  }

}
@media only screen and (max-width: 360px) {
  .menu_item {
    cursor: pointer;
  } */

/* } */
