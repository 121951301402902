.section_text-main {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.section_text-text {
    /* font-family: 'Gupter', serif; */
    font-size: 15px;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 2em;
    max-width: 80%;
}
