.custom_button {
    height: 30px;
    text-align: center;
    cursor: pointer;
    background: #cb9286;
    border-color: #8080801c;
    border: 1px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
}

.custom_button:hover {
    /* background: #b37f74; */
    background: #ffe5c6;
    /* background: #ffdfdf24; */
}

/* .rsvp_button {
	height: 30px;
	text-align: center;
	cursor: pointer;
  background: #8080801c;
  border-color: #8080801c;
  border: 1px;
  border-radius: 2px;
  font-size: 14px;
}

.rsvp_button:hover {
  background: #8080804a;
} */

/* .box-3 { background-color: #66A182; }
.rsvp_button {
	line-height: 50px;
	height: 20px;
	text-align: center;
	width: 100%;
	cursor: pointer;
}

.rsvp_button-three {
	color: #FFF;
	transition: all 0.5s;
	position: relative;
}
.rsvp_button-three::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.1);
	transition: all 0.3s;
}
.rsvp_button-three:hover::before {
	opacity: 0 ;
	transform: scale(0.5,0.5);
}
.rsvp_button-three::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border: 1px solid rgba(255,255,255,0.5);
	transform: scale(1.2,1.2);
}
.rsvp_button-three:hover::after {
	opacity: 1;
	transform: scale(1,1);
} */
