.contact {
    width: 100%;
    background: #cb9286;
    padding: 0 0 50px 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px 0 #969696;
}
.contact_box {
    margin-top: 40px;
    margin-bottom: 40px;
    /* border: 2px solid white;
  border-radius: 4px; */
}
.contact_title-main {
    margin-top: 80px;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
}

.contact_title-text {
    /* font-family: 'Gupter', serif; */
    color: white;
    font-size: 40px;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 1em;
}
.contact_undertitle-main {
    color: white;
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.contact_undertitle-text {
    /* font-family: 'Gupter', serif; */
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: bold;
    font-style: normal;
    line-height: 1em;
}
.contact_text-main {
    color: white;
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.contact_text-text {
    /* font-family: 'Gupter', serif; */
    color: white;
    font-size: 15px;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 1em;
    max-width: 80%;
}
