.logi {
    width: 100%;
    /* height: 100vh; */
    padding: 0 0 50px 0;
    background: #faf4f3;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px 0 #969696;
}
.logi_button_margin {
    margin-top: 10px;
}
