.section_undertitle-main {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.section_undertitle-text {
    /* font-family: 'Gupter', serif; */
    font-size: 20px;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 1em;
}
