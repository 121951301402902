.more_info {
    width: 100%;
    /* height: 100vh; */
    padding: 0 0 50px 0;
    background: #faf4f3;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px 0 #969696;
}
.more_info_button_margin {
    margin-top: 10px;
}

.more_info_section_text-main {
    margin-top: 0px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.more_info_section_text-text {
    /* font-family: 'Gupter', serif; */
    font-size: 15px;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 2em;
    max-width: 80%;
}
.more_info_round-picture_main {
    width: 320px;
    margin-top: 50px;
    border-radius: 50%;
    position: relative;
    /* object-fit: cover;
  align-self: center; */
    /* position: relative; */
    align-self: center;
}
.more_info_hero_information_icon {
    width: 220px;
    height: 220px;
    fill: #ff8278;
}
