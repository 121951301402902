.rsvp_form {
    color: white;
    padding: 30px;
    background: #cb9286;
}
.person {
    margin-top: 20px;
}
.input_box_wrapper {
    display: grid;
    margin-top: 10px;
}
.input_box {
    margin-top: 40px;
    display: grid;
}
.email_text_header {
    font-size: 10px;
    text-align: left;
}
.label_osa {
    text-align: left;
    font-size: 17px;
}

.input_box_field {
    font-size: 20px;
    border-radius: 5px;
}
input {
    border: none;
    color: #8c3c3c;
    padding: 5px;
}
textarea {
    border: none;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.person_header {
    font-size: 25px;
    border-bottom: 1px solid;
    display: flex;
}
.person_header_text {
    margin-right: 20px;
}

.rsvp_button_add_person {
    margin-top: 30px;
}

.rsvp_radio_header {
    text-align: left;
}

.rsvp_radio {
    display: flex;
}
.rsvp_error {
    color: red;
    text-align: left;
}

.rsvp_radio_button {
    margin-right: 20px;
}
.rsvp_button {
    height: 30px;
    text-align: center;
    cursor: pointer;
    background: #ffdfdf70;
    border-color: #8080801c;
    border: 1px;
    border-radius: 5px;
    font-size: 14px;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
}

.rsvp_button:hover {
    /* background: #8080804a; */
    background: #ffe5c6;
}
.rsvp_button_big {
    font-family: 'Josefin Sans', sans-serif;
    height: 40px;
    width: 260px;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
    line-height: 1.5;
    background: #ffdfdf70;
    border-color: #8080801c;
    border: 1px;
    border-radius: 5px;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
}

.rsvp_button_big:hover {
    /* background: #8080804a; */
    background: #ffe5c6;
    /* background: #ffdfdf24; */
}

.rsvp_button_margin {
    margin-top: 15px;
}

.circle-loader_root {
    padding: 55px 16px;
    color: white;
}

.circle-loader {
    margin: 0 0 30px 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: #ffdfdf70;
    animation-name: loader-spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: content-box;
}

.circle-loader,
.circle-loader:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
}

.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #ffdfdf70;
    transition: border 500ms ease-out;
}

.checkmark {
    display: none;
    box-sizing: content-box;
}

.draw:after {
    opacity: 1;
    height: 4em;
    width: 2em;
    transform-origin: left top;
    border-right: 2px solid #ffdfdf70;
    border-top: 2px solid #ffdfdf70;
    content: '';
    display: block;
    left: 2em;
    top: 4em;
    position: absolute;
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-o-keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-moz-keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 2em;
        opacity: 1;
    }
    40% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
    100% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
}
@-o-keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 2em;
        opacity: 1;
    }
    40% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
    100% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
}
@-moz-keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 2em;
        opacity: 1;
    }
    40% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
    100% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
}
@-webkit-keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 2em;
        opacity: 1;
    }
    40% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
    100% {
        height: 4em;
        width: 2em;
        opacity: 1;
    }
}
/* .rsvp_button {
	height: 30px;
	text-align: center;
	cursor: pointer;
  background: #8080801c;
  border-color: #8080801c;
  border: 1px;
  border-radius: 2px;
  font-size: 14px;
}

.rsvp_button:hover {
  background: #8080804a;
} */

/*
.rsvp_button_remove {
  box-shadow: 0px 1px 0px 0px #f0f7fa;
  background:linear-gradient(to bottom, #c94550 5%, #c94550 100%);
  background-color:#c94550;
  border-radius:6px;
  border:1px solid #a73740;
  display:inline-block;
  cursor:pointer;
  color:#ffffff;
  font-family:Arial;
  font-size: 13px;
  font-weight:bold;
  text-decoration:none;
  text-shadow:0px -1px 0px #5b6178;
  margin-left: 10px;
}
.rsvp_button_remove:hover {
	background:linear-gradient(to bottom, #a73740 5%, #a73740 100%);
	background-color:#a73740;
}
.rsvp_button_remove:active {
	position:relative;
	top:1px;
}

.rsvp_button {
	box-shadow: 0px 1px 0px 0px #f0f7fa;
	background:linear-gradient(to bottom, #33bdef 5%, #019ad2 100%);
	background-color:#33bdef;
	border-radius:6px;
	border:1px solid #057fd0;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:10px;
	font-weight:bold;
	padding:3px 18px;
	text-decoration:none;
	text-shadow:0px -1px 0px #5b6178;
}
.rsvp_button:hover {
	background:linear-gradient(to bottom, #019ad2 5%, #33bdef 100%);
	background-color:#019ad2;
}
.rsvp_button:active {
	position:relative;
	top:1px;
} */
/* .rsvp_button {
	line-height: 50px;
	height: 50px;
	text-align: center;
	width: 250px;
	cursor: pointer;
}

.btn-three {
	color: #FFF;
	transition: all 0.5s;
	position: relative;
}
.btn-three::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.1);
	transition: all 0.3s;
}
.btn-three:hover::before {
	opacity: 0 ;
	transform: scale(0.5,0.5);
}
.btn-three::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border: 1px solid rgba(255,255,255,0.5);
	transform: scale(1.2,1.2);
}
.btn-three:hover::after {
	opacity: 1;
	transform: scale(1,1);
}




.rsvp_button {
  color: #5b6178 !important;
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px;
  border: 1px solid #5b6178 !important;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.rsvp_button:hover {
  color: #5b6178 !important;
  border-color: #5b6178 !important;
  transition: all 0.3s ease 0s;
} */
