.rsvp {
    background: #cb9286;
    padding: 50px 0 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 2px 0 #969696;
    min-height: 800px;
}
.osa-main {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    font-weight: bold;
}

.osa-text {
    /* font-family: 'Gupter', serif; */
    font-size: 40px;
    color: white;
    letter-spacing: 1px;
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: lighter;
    font-style: normal;
    line-height: 1em;
}
