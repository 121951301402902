.hero {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
}
.test-form-center {
    align-self: center;
}
.hero_image_background {
    /* width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden; */
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    /* background-position: center center;
  background-image: url("http://i.stack.imgur.com/2OrtT.jpg")
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat; */
}
.hero_image_background2 {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}

.hero_image_background_overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background: rgba(128,91,84,.5); */
    background: rgba(128, 91, 84, 0.3);
    z-index: 3;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    font-size: 50px;
    font-weight: bold;
    color: white;
}
.hero_information_container {
    background-color: #cb9286;
    width: 320px;
    height: 320px;
    /* margin-top: 200px; */
    /* margin-left: 50%; */
    margin-top: 100px;
    border-radius: 50%;
    /* box-shadow: -12px 10px 5px 0px black; */
    align-self: flex-end;
    margin-right: 70px;
}
.hero_information_title {
    padding-top: 120px;
    font-size: 31px;
    text-transform: uppercase;
    color: #ffe5c6;
}
.hero_information_text {
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    margin-top: 20px;
    background: #ffe5c6;
    border-radius: 10px;
    outline: none;
    color: #cb9286;
    border: none;
    padding: 3px 10px 3px 10px;
}

.hero_image {
    position: absolute;
    width: 50%;
    bottom: 0;
    right: 0;
    z-index: 5;
}
.hero_circle_big {
    height: 40%;
    width: 40%;
    background-color: #9a2b2b;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
}
.hero_circle_medium {
    height: 20%;
    width: 20%;
    background-color: #771e1e;
    border-radius: 50%;
    position: absolute;
    bottom: -1;
    right: -1;
}
.hero_text_old {
    font-family: 'Merriweather', serif;
    /* margin-bottom: 50vh; */
    color: #ffe5c6;
    font-size: 4vw;
    width: 100%;
    margin-top: 40px;
    text-decoration: line-through;
}
.hero_text {
    /* font-family: 'Merriweather', serif; */
    /* margin-bottom: 50vh; */
    color: #ffe5c6;
    font-size: 8vw;
    width: 100%;
    /* background: #cb92867d; */
    border-radius: 10px;
}
.hero_information_icon_container {
    position: relative;
    text-align: center;
}
.hero_information_icon {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    left: 130px;
    fill: #ff8278;
}
.hero_information_icon_circle {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    left: 130px;
    background-color: white;
    border-radius: 50%;
}

.hero_information_icon_corona {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    right: -40px;
    fill: #ff8278;
}
@media only screen and (max-width: 879px) {
    .hero_image_background2 {
        display: block;
    }
    .hero_image_background1 {
        display: none;
    }
    .hero_information_container {
        /* margin-left: 0px; */
        align-self: auto;
        margin-right: 0px;
    }
    .hero_information_container {
        align-self: auto;
        margin-right: 0px;
        background-color: #cb9286;
        width: 240px;
        height: 240px;
        /* margin-top: 200px; */
        /* margin-left: 50%; */
        margin-top: 100px;
        border-radius: 50%;
        /* box-shadow: -12px 10px 5px 0px black; */
    }
    .hero_information_title {
        padding-top: 80px;
        font-size: 18px;
        text-transform: uppercase;
        color: #ffe5c6;
    }
    .hero_information_text {
        font-size: 16px;
        margin-top: 20px;
        background: #ffe5c6;
        outline: none;
        color: #cb9286;
        border: none;
        padding: 3px 10px 3px 10px;
        font-weight: 500;
    }
}
@media only screen and (max-width: 679px) {
    .hero_text {
        font-size: 50px;
    }
    .hero_text_old {
        font-size: 31px;
    }
    .hero_information_container {
        /* margin-left: 0px; */
        margin-top: 130px;
    }
}
@media only screen and (max-width: 500px) {
    .hero_information_container {
        /* margin-left: 0px; */
        margin-top: 160px;
    }
}
/*
@media only screen and (max-width: 879px) {
  .hero_image_background2 {
    display: block;
  }
  .hero_image_background1 {
    display: none;
  }

}
@media only screen and (max-width: 879px) {
  .hero_image_background2 {
    display: block;
  }
  .hero_image_background1 {
    display: none;
  }

} */
