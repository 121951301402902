.round-picture_main {
    height: 320px;
    width: 320px;
    margin-top: 50px;
    border-radius: 50%;
    position: relative;
    /* object-fit: cover;
  align-self: center; */
    /* position: relative; */
    align-self: center;
}

.round-picture {
    height: 320px;
    width: 320px;
    /* margin-top: 50px; */
    border-radius: 50%;
    object-fit: cover;
    /* padding:1px; */
    /* border:1px solid #8e8e8e; */
    background-color: #fdfdf0;
}

.round-picture_overlay {
    height: 320px;
    width: 320px;
    /* margin-top: 50px; */
    border-radius: 50%;
    /* object-fit: cover;
  align-self: center; */
    position: absolute;
    top: 0px;
    /* background: rgba(128,91,84,.5); */
    /* background: rgba(128, 91, 84, 0.2); */

    z-index: 3;
}
